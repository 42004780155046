

















import Vue from 'vue';
import { EmailAddressService } from '@/lib/services';
import { Component } from 'vue-property-decorator';
import { unwrapError } from '@/lib/helpers';
import Logo from '@/components/Logo.vue';

@Component({
  components: { Logo },
})
export default class EmailVerify extends Vue {
  loading: boolean = true;

  async mounted(): Promise<void> {
    try {
      await EmailAddressService.verify(this.$route.query);
      this.loading = false;
    } catch (e) {
      this.$toaster.error('Error', unwrapError(e));
    }
  }
}
